import {
  LOGIN,
  FETCH_PROFILE,
  LOGOUT,
  UPDATE_PROFILE,
  SUBMIT_EXPERIENCE,
  FETCH_EXPERIENCES,
  SIGNUP_SUBMIT,
  DELETE_EXPERIENCE,
  DOWNLOAD_CV,
  LOAD_COOKIE,
  DELETE_COURSE,
  FETCH_COURSES,
  SUBMIT_COURSE,
  FETCH_REFERENCES, SUBMIT_REFERENCE, DELETE_REFERENCE, FETCH_STRENGTHS, SUBMIT_STRENGTH, DELETE_STRENGTH, WAKE_SERVER
} from "./actionTypes"

export const wakeServerAction = content => ({
  type: WAKE_SERVER,
  payload: content
})

export const loginAction = content => ({
  type: LOGIN,
  payload: {
    email: content.email,
    password: content.password
  }
});

export const logoutAction = () => ({
  type: LOGOUT
})

export const loadCookieAction = () => ({
  type: LOAD_COOKIE
})

export const fetchProfileAction = () => ({
  type: FETCH_PROFILE
})

export const updateProfileAction = content => ({
  type: UPDATE_PROFILE,
  payload: content
})

export const submitExperienceAction = content => ({
  type: SUBMIT_EXPERIENCE,
  payload: content
})

export const deleteExperienceAction = content => ({
  type: DELETE_EXPERIENCE,
  payload: content
})

export const fetchExperiencesAction = () => ({
  type: FETCH_EXPERIENCES
})

export const signUpAction = content => ({
  type: SIGNUP_SUBMIT,
  payload: content
})

export const downloadCVAction = content => ({
  type: DOWNLOAD_CV,
  payload: content
})

export const deleteCourseAction = content => ({
  type: DELETE_COURSE,
  payload: content
})

export const fetchCoursesAction = content => ({
  type: FETCH_COURSES,
  payload: content
})

export const submitCourseAction = content => ({
  type: SUBMIT_COURSE,
  payload: content
})

export const fetchReferencesAction = () => ({
  type: FETCH_REFERENCES
})

export const submitReferenceAction = content => ({
  type: SUBMIT_REFERENCE,
  payload: content
})

export const deleteReferenceAction = content => ({
  type: DELETE_REFERENCE,
  payload: content
})

export const fetchStrengthsAction = () => ({
  type: FETCH_STRENGTHS
})

export const submitStrengthAction = content => ({
  type: SUBMIT_STRENGTH,
  payload: content
})

export const deleteStrengthAction = content => ({
  type: DELETE_STRENGTH,
  payload: content
})