import {FETCH_STRENGTHS, FETCH_STRENGTHS_FAILED, FETCH_STRENGTHS_SUCCESS, LOGOUT, SUBMIT_STRENGTH} from "../actionTypes"

const initialState = {
  strengths: [],
  loading: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_STRENGTHS: {
      return {
        ...state,
        loading: true
      };
    }
    case SUBMIT_STRENGTH: {
      return {
        ...state,
        loading: true
      };
    }
    case FETCH_STRENGTHS_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case FETCH_STRENGTHS_SUCCESS: {
      return {
        ...state,
        loading: false,
        strengths: action.payload
      };
    }
    case LOGOUT: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
