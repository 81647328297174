import React, {Component} from 'react'
import connect from "react-redux/es/connect/connect"
import {fetchStrengthsAction, submitStrengthAction, deleteStrengthAction} from "../redux/actions"
import Loader from "./Loader"

class Strengths extends Component {
  constructor(props){
    super(props)
    this.formRef = React.createRef()
  }

  componentDidMount() {
    this.props.fetchStrengthsAction()
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.submitStrengthAction(this.state)
    this.setState({})
    this.formRef.current.reset()
  }

  renderField(label, name, type, options) {
    return (
      <div className="form-group">
        <label>{label}</label>
        <input type={type} className="form-control" {...options} defaultValue={this.props[name]}
               onChange={(e) => this.setState({[name]: e.target.value})}/>
      </div>
    )
  }


  renderStrengths() {
    return this.props.strengths.map(strength => {
      return (
        <div className="col mb-4" key={strength.type.name} onClick={() => {
          this.props.deleteStrengthAction(strength.uuid)
        }}>
          <div className="card">
            <div className="card-body">
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="card-text lead">{strength.type.name}</div>
            </div>
          </div>
        </div>
      )
    })
  }

  renderStrengthSelect() {
    const options = [
      {"name": "Communication"},
      {"name": "Problem Solving"},
      {"name": "Computer Literacy"},
      {"name": "Leadership"},
      {"name": "Positivity"},
      {"name": "Self-Discipline"},
      {"name": "Organization"},
      {"name": "Independence"},
      {"name": "Strong Work Ethic"},
      {"name": "Integrity"},
      {"name": "Teamwork"},
      {"name": "Flexibility"},
      {"name": "Creativity"},
      {"name": "Loyalty"},
      {"name": "Time Management"},
      {"name": "Gathering Information"},
      {"name": "Attention to Detail"},
      {"name": "Active Listening"},
      {"name": "Self-awareness"},
      {"name": "Hospitable"},
      {"name": "Resilience"},
      {"name": "Friendliness"},
      {"name": "Punctuality"},
      {"name": "Fast Learning"},
      {"name": "Self-learning"},
      {"name": "Adaptivity"},
      {"name": "Client Relations"},
      {"name": "Motivation"},
      {"name": "Conflict Resolution"},
      {"name": "Listening"},
      {"name": "Tactfulness"},
      {"name": "Calmness"},
      {"name": "Public Speaking"},
      {"name": "Troubleshooting"},
      {"name": "Prioritizing"},
      {"name": "Decision Making"},
      {"name": "Focus"},
      {"name": "Managing Expectations"},
      {"name": "Personability"},
      {"name": "Collaboration"}
    ]
    return (
      <div className="form-group">
        <label>Strength</label>
        <select name="industry" className="form-control" defaultValue="" onChange={(e) => this.setState({name: e.target.value})} required>
          <option value="" disabled>Select a strength...</option>
          {options.map(opt => { return <option key={opt.name} value={opt.name}>{opt.name}</option> })}
        </select>
      </div>
    )
  }

  render() {
    return (
      <div className="card">
        {this.props.loading ? <Loader/> : null}
        <div className="card-header">Strengths</div>
        <div className="card-body">
          <div className="row row-cols-1 row-cols-md-2">
            {this.renderStrengths()}
          </div>
          <div className="card mb-3">
            <div className="card-header">Add strength</div>
            <form onSubmit={this.handleSubmit.bind(this)} className="card-body" ref={this.formRef}>
              {this.renderStrengthSelect()}
              <button type="submit" className="btn btn-primary">Save</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const strengths = state.strengths.strengths
  const loading = state.strengths.loading
  return {strengths, loading}
}
export default connect(mapStateToProps, {
  fetchStrengthsAction,
  submitStrengthAction,
  deleteStrengthAction
})(Strengths)