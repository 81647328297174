import React, {Component} from 'react'
import connect from "react-redux/es/connect/connect"
import {deleteCourseAction, fetchCoursesAction, submitCourseAction} from "../redux/actions"
import moment from "moment"
import Loader from "./Loader"

class Education extends Component {
  constructor(props){
    super(props)
    this.state = {
      completed: true // needed so that 'completed' is never null
    }
    this.formRef = React.createRef()
  }

  componentDidMount() {
    this.props.fetchCoursesAction()
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.submitCourseAction(this.state)
    this.setState({})
    this.formRef.current.reset()
  }

  renderField(label, name, type, options) {
    return (
      <div className="form-group">
        <label>{label}</label>
        <input type={type} className="form-control" defaultValue={this.props[name]} defaultChecked={true}
               {...options}
               onChange={(e) => this.setState({[name]: e.target.value})}/>
      </div>
    )
  }

  renderCheckbox(label, name) {
    return (
      <div className="form-group custom-control custom-switch">
        <input type="checkbox" className="custom-control-input" id="customSwitch1" defaultChecked={true}
               onChange={(e) => this.setState({[name]: e.target.checked})}/>
        <label className="custom-control-label" htmlFor="customSwitch1">{label}</label>
      </div>
    )
  }

  renderSelect(label, name, options, required) {
    return (
      <div className="form-group">
        <label>{label}</label>
        <select name={name} onChange={(e) => this.setState({[name]: e.target.value})} className="custom-select"
                required={required} defaultValue="">
          <option value="" disabled hidden>Select...</option>
          {options.map(option => {
            return <option key={option} value={option}>{option}</option>
          })}
        </select>
      </div>
    )
  }

  renderCourses() {
    return this.props.courses.map(exp => {
      return (
        <div className="col mb-4" key={exp.institution + exp.startDate} onClick={() => {
          this.props.deleteCourseAction(exp.uuid)
        }}>
          <div className="card">
            <div className="card-body">
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 className="card-text text-muted">
                <small>{exp.type.name}</small>
              </h5>
              <h5 className="card-title"><strong>{exp.institution}</strong></h5>
              <h5 className="card-title">{exp.field}</h5>
              <p
                className="card-text">{moment(exp.startDate).format('MMMM YYYY')} to {exp.endDate ? moment(exp.endDate).format('MMMM YYYY') : "Present"} {exp.completed ? "" : " (incomplete)"}</p>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div className="card">
        {this.props.loading ? <Loader/> : null}
        <div className="card-header">Education</div>
        <div className="card-body">
          <div className="row row-cols-1 row-cols-md-2">
            {this.renderCourses()}
          </div>
          <div className="card mb-3">
            <div className="card-header">Add education</div>
            <form onSubmit={this.handleSubmit.bind(this)} className="card-body" ref={this.formRef}>
              {this.renderField("Institution", "institution", "text", {required: true})}
              {this.renderSelect("Type", "type", ["Primary School", "High School (Grade 9)", "High School (Matric)", "Short Course", "Diploma", "Bachelor's Degree", "Master's Degree", "Doctorate"], {required: true})}
              {this.renderField("Field (optional)", "field", "text")}
              {this.renderCheckbox("Completed", "completed")}
              {this.renderField("Start Date", "startDate", "date", {required: true})}
              {this.renderField("End Date", "endDate", "date", {required: this.state.completed})}
              { this.state.completed ?
                null
                : <div className="alert alert-info" role="alert">
                  <small>You may leave "end date" empty if you are still busy with this course</small>
                </div>
              }
              <button type="submit" className="btn btn-primary">Save</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const courses = state.education.courses
  const loading = state.education.loading
  return {courses, loading}
}
export default connect(mapStateToProps, {fetchCoursesAction, submitCourseAction, deleteCourseAction})(Education)

