import React, {Component} from 'react'
import connect from "react-redux/es/connect/connect"
import {
  fetchExperiencesAction,
  fetchProfileAction,
  fetchCoursesAction,
  fetchReferencesAction,
  fetchStrengthsAction
} from "../redux/actions"
import moment from 'moment'
import _ from 'lodash'
import Loader from "../components/Loader"

class CV extends Component {
  componentDidMount() {
    this.props.fetchExperiencesAction()
    this.props.fetchCoursesAction()
    this.props.fetchProfileAction()
    this.props.fetchReferencesAction()
    this.props.fetchStrengthsAction()
  }

  renderCourses() {
    return this.props.courses.map(exp => {
      return (
        <div className="col my-2 px-0" key={exp.institution + exp.startDate}>
          <div className="card">
            <div className="card-body py-3">
              <h6 className="card-text text-muted my-1">
                <small>{exp.type.name}</small>
              </h6>
              <h5 className="card-title my-1"><strong>{exp.institution}</strong></h5>
              <h6 className="card-title my-1">{exp.field}</h6>
              <p className="card-text">{moment(exp.startDate).format('MMMM YYYY')} to {exp.endDate ? moment(exp.endDate).format('MMMM YYYY') : "Present"} {exp.completed ? "" : " (incomplete)"}</p>
            </div>
          </div>
        </div>
      )
    })
  }

  renderExperiences() {
    return this.props.experiences.map(exp => {
      return (
        <div className="col my-2 px-0" key={exp.company + exp.jobTitle}>
          <div className="card">
            <div className="card-body py-3">
              <h6 className="card-title text-muted my-1">
                {exp.company}
              </h6>
              <h5 className="card-title my-1"><strong>{exp.jobTitle}</strong></h5>
              <p className="card-text">{moment(exp.startDate).format('MMMM YYYY')} to {exp.endDate ? moment(exp.endDate).format('MMMM YYYY') : "Present"}</p>
            </div>
          </div>
        </div>
      )
    })
  }

  renderReferences() {
    return this.props.references.map(ref => {
      return (
        <div className="col my-3 px-0" key={ref.mobile}>
          <p className="card-title m-0"><strong>{ref.name} </strong><span
            className="card-title text-muted m-0">{ref.relation}</span></p>
          <p className="card-text">Mobile: {ref.mobile}</p>
        </div>
      )
    })
  }

  renderLanguages() {
    if(this.props.user.languages){
      return this.props.user.languages.map(lang => {
        return <p className="my-0" key={lang}>{lang}</p>
      })
    } else return null
  }

  generateStrengthBlurb(){
    const strengthWords = this.props.strengths.map(str => {return str.type.name.toLowerCase()}).join(', ').replace(/,(?!.*,)/g, ' and')
    return `${this.props.user.firstNames} is strong in the areas of ${strengthWords}`
  }

  generateWorkExperienceBlurb(experiences){
    const expString = _(experiences).groupBy(exp => exp.industry.name).keys().value().join(', ').toLowerCase().replace(/,(?!.*,)/g, ' and')
    return ` and has experience in ${expString}`
  }

  render() {
    return (
      <div className="container" style={{padding: '5% 6%'}}>
        {this.props.loading ? <Loader/> : null}
        <div className="text-center">
          <h6>Curriculum Vitae of</h6>
          <h1 className="font-weight-light text-wrap">{this.props.user.firstNames} {this.props.user.lastNames}</h1>
          <hr/>
        </div>
        <div className="row">
          <div className="col-sm">
            <div className="py-3">
              <h3>Contact Information</h3>
              <p className="my-0"><strong>Email</strong> {this.props.user.email}</p>
              <p className="my-0"><strong>Mobile</strong> {this.props.user.mobile}</p>
              <p className="my-0"><strong>City</strong> {this.props.user.city}</p>
            </div>
            <div className="py-3">
              <h3>Languages</h3>
              {this.renderLanguages()}
            </div>
            <div className="py-3">
              <h3>References</h3>
              {this.renderReferences()}
            </div>
            <div className="py-3">
              <h3>Introduction</h3>
              <p className="pr-3">
                {this.generateStrengthBlurb()+this.generateWorkExperienceBlurb(this.props.experiences)}
              </p>
            </div>
          </div>
          <div className="col-sm">
            <div className="py-3">
              <h3>Education</h3>
              {this.renderCourses()}
            </div>
            <div className="py-3">
              <h3>Work Experience</h3>
              {this.renderExperiences()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const user = state.user
  const experiences = state.experience.experiences
  const courses = state.education.courses
  const references = state.references.references
  const strengths = state.strengths.strengths
  const loading = state.experience.loading || state.education.loading || state.references.loading || state.strengths.loading || state.user.profileLoading
  return {user, experiences, courses, references, strengths, loading}
}

export default connect(mapStateToProps, {
  fetchExperiencesAction,
  fetchProfileAction,
  fetchCoursesAction,
  fetchReferencesAction,
  fetchStrengthsAction
})(CV)