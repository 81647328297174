import {put, takeLatest} from 'redux-saga/effects'
import {decode} from 'jsonwebtoken'
import cookie from "react-cookies"
import {
  DELETE_COURSE,
  DELETE_COURSE_SUCCESS,
  DELETE_EXPERIENCE,
  DELETE_EXPERIENCE_SUCCESS,
  DELETE_REFERENCE,
  DELETE_REFERENCE_SUCCESS, DELETE_STRENGTH, DELETE_STRENGTH_SUCCESS,
  DOWNLOAD_CV,
  FETCH_COURSES,
  FETCH_COURSES_FAILED,
  FETCH_COURSES_SUCCESS,
  FETCH_EXPERIENCES,
  FETCH_EXPERIENCES_FAILED,
  FETCH_EXPERIENCES_SUCCESS,
  FETCH_PROFILE,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_SUCCESS,
  FETCH_REFERENCES,
  FETCH_REFERENCES_FAILED,
  FETCH_REFERENCES_SUCCESS, FETCH_STRENGTHS,
  FETCH_STRENGTHS_FAILED,
  FETCH_STRENGTHS_SUCCESS,
  LOAD_COOKIE,
  LOAD_COOKIE_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  SIGNUP_SUBMIT,
  SIGNUP_SUBMIT_FAILED,
  SIGNUP_SUBMIT_SUCCESS,
  SUBMIT_COURSE,
  SUBMIT_COURSE_FAILED,
  SUBMIT_COURSE_SUCCESS,
  SUBMIT_EXPERIENCE,
  SUBMIT_EXPERIENCE_FAILED,
  SUBMIT_EXPERIENCE_SUCCESS,
  SUBMIT_REFERENCE,
  SUBMIT_REFERENCE_FAILED,
  SUBMIT_REFERENCE_SUCCESS, SUBMIT_STRENGTH, SUBMIT_STRENGTH_FAILED, SUBMIT_STRENGTH_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAILED,
  UPDATE_PROFILE_SUCCESS, WAKE_SERVER
} from "../actionTypes"

const apiRoot = "/api"

function* wakeServer(action) {
  yield fetch(`${apiRoot}/wakeup?from=${action.payload}`)
}

function* tryLoginUser(action) {
  const response = yield fetch(`${apiRoot}/login`, {
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(action.payload)
  })

  if(response.status === 200){
    const decodedToken = decode(cookie.load('token'))
    if(!decodedToken) yield put({type: LOGIN_FAILED, payload: "could not load token from cookie"})
    yield put({type: LOGIN_SUCCESS, payload: decodedToken})
  } else {
    const err = yield response.text()
    yield put({type: LOGIN_FAILED, payload: err})
  }
}

function* logout() {
  cookie.remove('token', {path: '/'})
  yield put({type: LOGOUT_SUCCESS})
}

function* tryLoadCookie(){
  const decodedToken = decode(cookie.load('token'))
  if(!decodedToken) yield put({type: LOGOUT})
  else yield put({type: LOAD_COOKIE_SUCCESS, payload: decodedToken})
}

function* tryFetchProfile() {
  const response = yield fetch(`${apiRoot}/user/profile`)

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const profile = yield response.json()
    yield put({type: FETCH_PROFILE_SUCCESS, payload: profile})
  } else {
    yield put({type: FETCH_PROFILE_FAILED})
  }
}

function* tryUpdateProfile(action) {
  const response = yield fetch(`${apiRoot}/user/updateProfile`, {
    method: "POST",
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    body: JSON.stringify(action.payload)
  })

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const user = yield response.json()
    yield put({type: UPDATE_PROFILE_SUCCESS, payload: user})
  } else {
    const err = yield response.text()
    yield put({type: UPDATE_PROFILE_FAILED, payload: err})
  }

}

function* trySubmitExperience(action) {
  const response = yield fetch(`${apiRoot}/experience`, {
    method: "POST",
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    body: JSON.stringify(action.payload)
  })

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const experience = yield response.json()
    yield put({type: SUBMIT_EXPERIENCE_SUCCESS, payload: experience})
    yield put({type: FETCH_EXPERIENCES})
  } else {
    const err = yield response.text()
    yield put({type: SUBMIT_EXPERIENCE_FAILED, payload: err})
  }
}

function* tryDeleteExperience(action) {
  const response = yield fetch(`${apiRoot}/experience`, {
    method: "DELETE",
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    body: JSON.stringify({uuid: action.payload})
  })

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const experience = yield response.json()
    yield put({type: DELETE_EXPERIENCE_SUCCESS, payload: experience})
    yield put({type: FETCH_EXPERIENCES})
  } else {
    const err = yield response.text()
    console.log("could not delete experience", err)
  }
}

function* tryFetchExperiences() {
  const response = yield fetch(`${apiRoot}/experience/`)

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const content = yield response.json()
    yield put({type: FETCH_EXPERIENCES_SUCCESS, payload: content})
  } else {
    yield put({type: FETCH_EXPERIENCES_FAILED})
  }
}

function* trySubmitCourse(action) {
  const response = yield fetch(`${apiRoot}/course`, {
    method: "POST",
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    body: JSON.stringify(action.payload)
  })

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const course = yield response.json()
    yield put({type: SUBMIT_COURSE_SUCCESS, payload: course})
    yield put({type: FETCH_COURSES})
  } else {
    const err = yield response.text()
    yield put({type: SUBMIT_COURSE_FAILED, payload: err})
  }
}

function* tryDeleteCourse(action) {
  const response = yield fetch(`${apiRoot}/course`, {
    method: "DELETE",
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    body: JSON.stringify({uuid: action.payload})
  })

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const course = yield response.json()
    yield put({type: DELETE_COURSE_SUCCESS, payload: course})
    yield put({type: FETCH_COURSES})
  } else {
    const err = yield response.text()
    console.log("could not delete course", err)
  }
}

function* tryFetchCourses() {
  const response = yield fetch(`${apiRoot}/course/`)

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const content = yield response.json()
    yield put({type: FETCH_COURSES_SUCCESS, payload: content})
  } else {
    yield put({type: FETCH_COURSES_FAILED})
  }
}

function* tryFetchReferences() {
  const response = yield fetch(`${apiRoot}/reference/`)

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const content = yield response.json()
    yield put({type: FETCH_REFERENCES_SUCCESS, payload: content})
  } else {
    yield put({type: FETCH_REFERENCES_FAILED})
  }
}

function* trySubmitReference(action) {
  const response = yield fetch(`${apiRoot}/reference`, {
    method: "POST",
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    body: JSON.stringify(action.payload)
  })

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const reference = yield response.json()
    yield put({type: SUBMIT_REFERENCE_SUCCESS, payload: reference})
    yield put({type: FETCH_REFERENCES})
  } else {
    const err = yield response.text()
    yield put({type: SUBMIT_REFERENCE_FAILED, payload: err})
  }
}

function* tryDeleteReference(action) {
  const response = yield fetch(`${apiRoot}/reference`, {
    method: "DELETE",
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    body: JSON.stringify({uuid: action.payload})
  })

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const reference = yield response.json()
    yield put({type: DELETE_REFERENCE_SUCCESS, payload: reference})
    yield put({type: FETCH_REFERENCES})
  } else {
    const err = yield response.text()
    console.log("could not delete reference", err)
  }
}

function* tryFetchStrengths() {
  const response = yield fetch(`${apiRoot}/strength/`)

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const content = yield response.json()
    yield put({type: FETCH_STRENGTHS_SUCCESS, payload: content})
  } else {
    yield put({type: FETCH_STRENGTHS_FAILED})
  }
}

function* trySubmitStrength(action) {
  const response = yield fetch(`${apiRoot}/strength`, {
    method: "POST",
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    body: JSON.stringify(action.payload)
  })

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const strength = yield response.json()
    yield put({type: SUBMIT_STRENGTH_SUCCESS, payload: strength})
    yield put({type: FETCH_STRENGTHS})
  } else {
    const err = yield response.text()
    yield put({type: SUBMIT_STRENGTH_FAILED, payload: err})
  }
}

function* tryDeleteStrength(action) {
  const response = yield fetch(`${apiRoot}/strength`, {
    method: "DELETE",
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    body: JSON.stringify({uuid: action.payload})
  })

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const strength = yield response.json()
    yield put({type: DELETE_STRENGTH_SUCCESS, payload: strength})
    yield put({type: FETCH_STRENGTHS})
  } else {
    const err = yield response.text()
    console.log("could not delete strength", err)
  }
}

function* trySubmitSignup(action) {
  const response = yield fetch(`${apiRoot}/signup`, {
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(action.payload)
  })

  if(response.status === 200){
    const decodedToken = decode(cookie.load('token'))
    if(!decodedToken) yield put({type: SIGNUP_SUBMIT_FAILED, payload: "could not load token from cookie"})
    yield put({type: SIGNUP_SUBMIT_SUCCESS, payload: decodedToken})
  } else {
    const err = yield response.text()
    yield put({type: SIGNUP_SUBMIT_FAILED, payload: err})
  }
}

function* tryDownloadCV(action) {
  const response = yield fetch(`${apiRoot}/pdf/create`, {
    method: "POST",
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    body: JSON.stringify({html: action.payload})
  })

  if (response.status === 401) {
    yield put({type: LOGOUT})
  } else if (response.status === 200) {
    const blob = yield response.blob()
    const url = URL.createObjectURL(blob)

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = "cv.pdf";
    a.click();
    document.body.removeChild(a);

  } else {
    const err = yield response.text()
    alert(`There was a problem downloading your CV. ${err}`)
  }
}

function* mySaga() {
  yield takeLatest(WAKE_SERVER, wakeServer)

  yield takeLatest(LOGIN, tryLoginUser)
  yield takeLatest(LOGOUT, logout)

  yield takeLatest(LOAD_COOKIE, tryLoadCookie)

  yield takeLatest(FETCH_PROFILE, tryFetchProfile)
  yield takeLatest(UPDATE_PROFILE, tryUpdateProfile)

  yield takeLatest(SUBMIT_EXPERIENCE, trySubmitExperience)
  yield takeLatest(FETCH_EXPERIENCES, tryFetchExperiences)
  yield takeLatest(DELETE_EXPERIENCE, tryDeleteExperience)

  yield takeLatest(FETCH_COURSES, tryFetchCourses)
  yield takeLatest(SUBMIT_COURSE, trySubmitCourse)
  yield takeLatest(DELETE_COURSE, tryDeleteCourse)

  yield takeLatest(FETCH_REFERENCES, tryFetchReferences)
  yield takeLatest(SUBMIT_REFERENCE, trySubmitReference)
  yield takeLatest(DELETE_REFERENCE, tryDeleteReference)

  yield takeLatest(FETCH_STRENGTHS, tryFetchStrengths)
  yield takeLatest(SUBMIT_STRENGTH, trySubmitStrength)
  yield takeLatest(DELETE_STRENGTH, tryDeleteStrength)

  yield takeLatest(SIGNUP_SUBMIT, trySubmitSignup)

  yield takeLatest(DOWNLOAD_CV, tryDownloadCV)
}

export default mySaga