import {FETCH_COURSES, FETCH_COURSES_FAILED, FETCH_COURSES_SUCCESS, LOGOUT, SUBMIT_COURSE} from "../actionTypes"

const initialState = {
  courses: [],
  loading: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_COURSES: {
      return {
        ...state,
        loading: true
      };
    }
    case SUBMIT_COURSE: {
      return {
        ...state,
        loading: true
      };
    }
    case FETCH_COURSES_SUCCESS: {
      return {
        ...state,
        loading: false,
        courses: action.payload
      };
    }
    case FETCH_COURSES_FAILED: {
      return {
        ...state,
        loading: false,
        courses: action.payload
      };
    }
    case LOGOUT: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
