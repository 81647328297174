import React, {Component} from 'react'
import '../App.css'
import {getUserUUID} from "../redux/selectors"
import connect from "react-redux/es/connect/connect"
import {loginAction, wakeServerAction} from "../redux/actions"
import {Redirect} from "react-router-dom"


class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: ''
    }
  }
  componentDidMount(){
    this.props.wakeServerAction('loginPage');
  }

  handleEmailChange(e) {
    this.setState({email: e.target.value})
  }

  handlePasswordChange(e) {
    this.setState({password: e.target.value})
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.loginAction({email: this.state.email, password: this.state.password})
  }

  render() {
    return (
      <div>
        {this.props.userUUID ? <Redirect to="/"/> : null}
        <nav className="navbar navbar-light">
          <a className="navbar-brand" href="/#">
            JobConnect
          </a>
        </nav>
        <div className="container">


          <div className="card-deck">
            <div className="col-sm">
              <div className={"card"}>
                <div className="card-body">
                  <h5 className="card-title">Sign in</h5>
                  <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="form-group">
                      <label htmlFor="email">email</label>
                      <input type="email" name="email" className="form-control" id="email"
                             onChange={this.handleEmailChange.bind(this)}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <input type="password" name="password" className="form-control" id="password"
                             onChange={this.handlePasswordChange.bind(this)}/>
                    </div>
                    {this.props.loginFailureMessage ? <div className="alert alert-warning" role="alert">
                      {this.props.loginFailureMessage}
                    </div> : null}
                    <button type="submit" className="btn btn-info"
                            disabled={this.props.loginLoading}>{this.props.loginLoading ? "Loading..." : "Submit"}</button>
                  </form>
                </div>
              </div>
            </div>

          </div>

          <div className="row">

          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  const userUUID = getUserUUID(state)
  const loginFailureMessage = state.user.loginFailureMessage
  const loginLoading = state.user.loginLoading
  return {userUUID, loginFailureMessage, loginLoading}
}
// export default TodoList;
export default connect(mapStateToProps, {loginAction, wakeServerAction})(LoginPage)