export const WAKE_SERVER = "WAKE_SERVER"

export const LOGIN = "LOGIN"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGOUT = "LOGOUT"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"

export const LOAD_COOKIE = "LOAD_COOKIE"
export const LOAD_COOKIE_SUCCESS = "LOAD_COOKIE_SUCCESS"

export const FETCH_PROFILE = "FETCH_PROFILE"
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS"
export const FETCH_PROFILE_FAILED = "FETCH_PROFILE_FAILED"

export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED"


export const SUBMIT_EXPERIENCE = "SUBMIT_EXPERIENCE"
export const SUBMIT_EXPERIENCE_SUCCESS = "SUBMIT_EXPERIENCE_SUCCESS"
export const SUBMIT_EXPERIENCE_FAILED = "SUBMIT_EXPERIENCE_FAILED"

export const DELETE_EXPERIENCE = "DELETE_EXPERIENCE"
export const DELETE_EXPERIENCE_SUCCESS = "DELETE_EXPERIENCE_SUCCESS"


export const FETCH_EXPERIENCES = "FETCH_EXPERIENCES"
export const FETCH_EXPERIENCES_SUCCESS = "FETCH_EXPERIENCES_SUCCESS"
export const FETCH_EXPERIENCES_FAILED = "FETCH_EXPERIENCES_FAILED"

export const SIGNUP_SUBMIT = "SIGNUP_SUBMIT"
export const SIGNUP_SUBMIT_SUCCESS = "SIGNUP_SUBMIT_SUCCESS"
export const SIGNUP_SUBMIT_FAILED = "SIGNUP_SUBMIT_FAILED"

export const DOWNLOAD_CV = "DOWNLOAD_CV"

export const SUBMIT_COURSE = "SUBMIT_COURSE"
export const SUBMIT_COURSE_SUCCESS = "SUBMIT_COURSE_SUCCESS"
export const SUBMIT_COURSE_FAILED = "SUBMIT_COURSE_FAILED"

export const DELETE_COURSE = "DELETE_COURSE"
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS"

export const FETCH_COURSES = "FETCH_COURSES"
export const FETCH_COURSES_SUCCESS = "FETCH_COURSES_SUCCESS"
export const FETCH_COURSES_FAILED = "FETCH_COURSES_FAILED"

export const FETCH_REFERENCES = "FETCH_REFERENCES"
export const FETCH_REFERENCES_SUCCESS = "FETCH_REFERENCES_SUCCESS"
export const FETCH_REFERENCES_FAILED = "FETCH_REFERENCES_FAILED"

export const SUBMIT_REFERENCE = "SUBMIT_REFERENCE"
export const SUBMIT_REFERENCE_SUCCESS = "SUBMIT_REFERENCE_SUCCESS"
export const SUBMIT_REFERENCE_FAILED = "SUBMIT_REFERENCE_FAILED"

export const DELETE_REFERENCE = "DELETE_REFERENCE"
export const DELETE_REFERENCE_SUCCESS = "DELETE_REFERENCE_SUCCESS"

export const FETCH_STRENGTHS = "FETCH_STRENGTHS"
export const FETCH_STRENGTHS_SUCCESS = "FETCH_STRENGTHS_SUCCESS"
export const FETCH_STRENGTHS_FAILED = "FETCH_STRENGTHS_FAILED"

export const SUBMIT_STRENGTH = "SUBMIT_STRENGTH"
export const SUBMIT_STRENGTH_SUCCESS = "SUBMIT_STRENGTH_SUCCESS"
export const SUBMIT_STRENGTH_FAILED = "SUBMIT_STRENGTH_FAILED"

export const DELETE_STRENGTH = "DELETE_STRENGTH"
export const DELETE_STRENGTH_SUCCESS = "DELETE_STRENGTH_SUCCESS"