import React, {Component} from 'react'
import '../App.css'
import {getUserUUID} from "../redux/selectors"
import connect from "react-redux/es/connect/connect"
import Profile from "../components/Profile"
import Experience from "../components/Experience"
import MyCV from "../components/MyCV"
import {logoutAction} from "../redux/actions"
import Education from "../components/Education"
import References from "../components/References"
import Strengths from "../components/Strengths"

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 'profile'
    }
  }

  renderContent() {
    switch (this.state.tab) {
      case 'profile':
        return <Profile/>
      case 'experience':
        return <Experience/>
      case 'education':
        return <Education/>
      case 'strengths':
        return <Strengths/>
      case 'references':
        return <References/>
      case 'mycv':
        return <MyCV/>
      default:
        return <Profile/>
    }
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-center">
          <span className="navbar-brand">
            JobConnect
          </span>
          <small className=" nav-link" onClick={() => this.props.logoutAction()}><a href="/#" className="text-muted">Log out</a></small>
          <ul className="nav nav-tabs">
            <small className={`p-2 nav-link ${this.state.tab === 'profile' ? 'active' : ''}`}
                   onClick={() => this.setState({tab: 'profile'})}>
              <span role="img" aria-label="profile" className="m-0 h5">📝</span>
              <span className="sr-only">(current)</span>
            </small>
            <small className={`p-2 nav-link ${this.state.tab === 'experience' ? 'active' : ''}`}
                   onClick={() => this.setState({tab: 'experience'})}>
              <span role="img" aria-label="experience" className="m-0 h5">💼</span>
              <span className="sr-only">(current)</span>
            </small>
            <small className={`p-2 nav-link ${this.state.tab === 'education' ? 'active' : ''}`}
                   onClick={() => this.setState({tab: 'education'})}>
              <span role="img" aria-label="education" className="m-0 h5">📚</span>
              <span className="sr-only">(current)</span>
            </small>
            <small className={`p-2 nav-link ${this.state.tab === 'strengths' ? 'active' : ''}`}
                   onClick={() => this.setState({tab: 'strengths'})}>
              <span role="img" aria-label="strengths" className="m-0 h5">💎</span>
              <span className="sr-only">(current)</span>
            </small>
            <small className={`p-2 nav-link ${this.state.tab === 'references' ? 'active' : ''}`}
                   onClick={() => this.setState({tab: 'references'})}>
              <span role="img" aria-label="references" className="m-0 h5">📇</span>
              <span className="sr-only">(current)</span>
            </small>
            <small className={`p-2 nav-link ${this.state.tab === 'mycv' ? 'active' : ''}`}
                   onClick={() => this.setState({tab: 'mycv'})}>
              <span role="img" aria-label="cv" className="m-0 h5">📄</span>
              <span className="sr-only">(current)</span>
            </small>
          </ul>
        </nav>
        <div className="container" style={{padding: '15px'}}>
          {this.renderContent()}
          <div className="text-center text-muted p-3">
            Need help? Email our support at <a href="mailto:support@job-connect.co.za">support@job-connect.co.za</a>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const userUUID = getUserUUID(state)
  return {userUUID}
}
// export default TodoList;
export default connect(mapStateToProps, {logoutAction})(HomePage)