import React, {Component} from 'react'
import '../App.css'
import {getUserUUID} from "../redux/selectors"
import connect from "react-redux/es/connect/connect"
import {signUpAction} from "../redux/actions"
import {Link} from "react-router-dom"
import image from "../assets/images/keenan-constance-lAjPSof6X_o-unsplash.jpg"


class SignUpCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: ''
    }
  }

  handleEmailChange(e) {
    this.setState({email: e.target.value})
  }

  handlePasswordChange(e) {
    this.setState({password: e.target.value})
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.signUpAction({email: this.state.email, password: this.state.password})
  }

  render() {
    return (
        <div className={"card text-center"}>
          <img src={image} className="card-img-top" alt="city"/>
          <h5 className="card-title card-header">Sign up</h5>
          <div className="card-body">
            <p className="text-muted">Enter your email address and a unique password to start creating your free CV</p>
            <form onSubmit={this.handleSubmit.bind(this)} className="text-left">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" name="email" className="form-control" id="email"
                       onChange={this.handleEmailChange.bind(this)}/>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input type="password" name="password" className="form-control" id="password"
                       onChange={this.handlePasswordChange.bind(this)}/>
              </div>
              {this.props.signupFailureMessage ? <div className="alert alert-warning" role="alert">
                {this.props.signupFailureMessage}
              </div> : null}
              <button type="submit" className="btn btn-info"
                      disabled={this.props.signupLoading}>{this.props.signupLoading ? "Loading..." : "Sign up"}</button>
              <div className="text-muted"><small>By signing up, you agree to our <Link to="/terms">terms and conditions</Link></small></div>
            </form>
          </div>
        </div>
    )
  }

}

const mapStateToProps = state => {
  const userUUID = getUserUUID(state)
  const signupFailureMessage = state.user.signupFailureMessage
  const signupLoading= state.user.signupLoading
  return {userUUID, signupFailureMessage, signupLoading}
}
// export default TodoList;
export default connect(mapStateToProps, {signUpAction})(SignUpCard)