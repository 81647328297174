import React, {Component} from 'react'
import '../App.css'
import {getUserUUID} from "../redux/selectors"
import connect from "react-redux/es/connect/connect"
import {
  Link
} from "react-router-dom"
import SignUpCard from "../components/signUpCard"
import image from "../assets/images/michael-browning-MtqG1lWcUw0-unsplash.jpg"
import {wakeServerAction} from "../redux/actions"

class LandingPage extends Component {

  componentDidMount(){
    this.props.wakeServerAction('landingPage');
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-light bg-light">
          <a className="navbar-brand" href="/#">
            JobConnect
          </a>
          <div className="form-inline my-2 my-lg-0">
            <Link to="/login">
              <button type="button" className="btn btn-info btn-sm">Log in</button>
            </Link>
          </div>
        </nav>
        <div className="container">

          <div className="my-3 p-3 text-center">
            <h2>Create a free CV. Get connected to jobs.</h2>
            <small>
              JobConnect is a free service to help you create and download your own professional CV.
              We also share your CV, allowing companies to find you. Get started below.
            </small>
          </div>

          <div className="card-deck my-3">
            <SignUpCard/>
            <div className="card text-center">
              <img src={image} className="card-img-top" alt="kitchen"/>
              <h5 className="card-title card-header">Are you an employer?</h5>
              <div className="card-body">
                <p className="card-text">JobConnect provides parametric searches for candidates in your industry</p>
                <button type="button" className="btn btn-info" onClick={() => alert("coming soon!")}>Go to employer's
                  portal
                </button>
              </div>
            </div>
          </div>
          <div className="text-center text-muted pb-3">
            <small>
              Need help? Email our support at <a href="mailto:support@job-connect.co.za">support@job-connect.co.za</a>
            </small>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const userUUID = getUserUUID(state)
  return {userUUID}
}
// export default TodoList;
export default connect(mapStateToProps, {wakeServerAction})(LandingPage)