import React, {Component} from 'react'
import './App.css'
import {getUserUUID} from "./redux/selectors"
import connect from "react-redux/es/connect/connect"
import LoginPage from "./pages/LoginPage"
import LandingPage from "./pages/LandingPage"
import {
  HashRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import {loadCookieAction} from "./redux/actions"
import TermsPage from "./pages/TermsPage"
import HomePage from "./pages/HomePage"

class App extends Component{
  componentDidMount(){
    this.props.loadCookieAction()
  }

  render(){
    return (
      <HashRouter>
        <Switch>
          <Route path="/login">
            <LoginPage/>
          </Route>
          <Route path="/home">
            {this.props.userUUID ? <HomePage/> : <Redirect to="/login"/>}
          </Route>
          <Route path="/terms">
            <TermsPage/>
          </Route>
          <Route path="/">
            {this.props.userUUID ? <Redirect to="/home"/> : <LandingPage/>}
          </Route>
        </Switch>
      </HashRouter>
    )
  }
}

const mapStateToProps = state => {
  const userUUID = getUserUUID(state)
  return {userUUID}
}
// export default TodoList;
export default connect(mapStateToProps, {loadCookieAction})(App)