import {
  FETCH_REFERENCES,
  FETCH_REFERENCES_FAILED,
  FETCH_REFERENCES_SUCCESS,
  LOGOUT,
  SUBMIT_REFERENCE
} from "../actionTypes"

const initialState = {
  references: [],
  loading: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_REFERENCES: {
      return {
        ...state,
        loading: true
      };
    }
    case SUBMIT_REFERENCE: {
      return {
        ...state,
        loading: true
      };
    }
    case FETCH_REFERENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        references: action.payload
      };
    }
    case FETCH_REFERENCES_FAILED: {
      return {
        ...state,
        loading: false,
        references: action.payload
      };
    }
    case LOGOUT: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
