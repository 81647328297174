import React, {Component} from 'react'
import '../App.css'
import {
  withRouter
} from "react-router-dom"

class TermsText extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <button type="button" className="btn btn-info my-3"
                  onClick={() => this.props.history.goBack()}>{`< Go Back`}</button>
          <div>
            <h1>Job Connect Service Terms</h1>
            <h6>By using Job Connect, you agree to:</h6>
            <ul>
              <li>
                Allow Job Connect ("us"/"the service"/"we") to capture and store data you provide (or, in the case that
                you're a reference to an application, the
                data that has been provided about you), including but not limited
                to: your name(s), email
                address, cell-phone number, etc, as well as technical information such as I.P. addresses.
                This information will be referred to as "your data" in this document.
              </li>
              <li>
                Allow us to share your data across the service, and with third parties. Your data is shared for the
                purposes of:
                <ul>
                  <li>
                    Helping you find a job (in the case that you are job-seeking user), by sharing your data with
                    potential employers.
                  </li>
                  <li>
                    Helping you find candidates (in the case that you are a potential employer), by sharing your data
                    with job-seeking users.
                  </li>
                  <li>
                    Statistical analysis and the gathering of usage information.
                  </li>
                  <li>
                    Improving the service.
                  </li>
                  <li>
                    Debugging technical issues and providing support.
                  </li>
                </ul>
              </li>
              <li>
                Receiving communication from us, through channels including, but not limited to, email, sms, etc.
              </li>
              <li>
                Being barred/banned from the service in the event that we deem your use of the service as
                being abusive or fraudulent.
              </li>
              <li>
                Not hold Job Connect liable for any loss or damage incurred through the use of the service.
              </li>
              <li>
                Acknowledge that use of the service does guarantee success in finding a job, or finding candidates.
              </li>
            </ul>
            <p>Please note that use of this service includes the use of various Google services, and where applicable,
            you are bound to their
              <a href="https://www.google.com/intl/en/policies/terms"> terms of service</a>. Please also see their
              <a href="https://www.google.com/policies/privacy"> privacy policy</a>.</p>
            <p>Thank you for reading our terms and conditions.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(TermsText)