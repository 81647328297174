import React from 'react'

export default function Loader () {
  return(
    <div style={
      {
        position: 'fixed',
        height: '100vh',
        zIndex: '1000',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: '0px',
        left: '0px',
        flexDirection: 'column',
        backgroundColor: 'rgba(125, 125, 125, 0.5)'
      }
    }>
      <div className="spinner-grow text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}