import React, {Component} from 'react'
import connect from "react-redux/es/connect/connect"
import {deleteExperienceAction, fetchExperiencesAction, submitExperienceAction} from "../redux/actions"
import moment from "moment"
import Loader from "./Loader"

class Experience extends Component {
  constructor(props){
    super(props)
    this.formRef = React.createRef()
  }

  componentDidMount() {
    this.props.fetchExperiencesAction()
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.submitExperienceAction(this.state)
    this.setState({})
    this.formRef.current.reset()
  }

  renderField(label, name, type, options) {
    return (
      <div className="form-group">
        <label>{label}</label>
        <input type={type} className="form-control" defaultValue={this.props[name]}
               onChange={(e) => this.setState({[name]: e.target.value})} {...options}/>
      </div>
    )
  }

  renderIndustrySelect() {
    return (
      <div className="form-group">
        <label>Industry</label>
        <select name="industry" className="form-control" defaultValue="" onChange={(e) => this.setState({industry: e.target.value})} required>
          <option value="" disabled>Select an industry...</option>
          <option value="Accounting">Accounting</option>
          <option value="Airlines/Aviation">Airlines/Aviation</option>
          <option value="Alternative Dispute Resolution">Alternative Dispute Resolution</option>
          <option value="Alternative Medicine">Alternative Medicine</option>
          <option value="Animation">Animation</option>
          <option value="Apparel/Fashion">Apparel/Fashion</option>
          <option value="Architecture/Planning">Architecture/Planning</option>
          <option value="Arts/Crafts">Arts/Crafts</option>
          <option value="Automotive">Automotive</option>
          <option value="Aviation/Aerospace">Aviation/Aerospace</option>
          <option value="Banking/Mortgage">Banking/Mortgage</option>
          <option value="Biotechnology/Greentech">Biotechnology/Greentech</option>
          <option value="Broadcast Media">Broadcast Media</option>
          <option value="Building Materials">Building Materials</option>
          <option value="Business Supplies/Equipment">Business Supplies/Equipment</option>
          <option value="Capital Markets/Hedge Fund/Private Equity">Capital Markets/Hedge Fund/Private Equity</option>
          <option value="Chemicals">Chemicals</option>
          <option value="Cleaning Services">Cleaning Services</option>
          <option value="Civic/Social Organization">Civic/Social Organization</option>
          <option value="Civil Engineering">Civil Engineering</option>
          <option value="Commercial Real Estate">Commercial Real Estate</option>
          <option value="Computer Games">Computer Games</option>
          <option value="Computer Hardware">Computer Hardware</option>
          <option value="Computer Networking">Computer Networking</option>
          <option value="Computer Software/Engineering">Computer Software/Engineering</option>
          <option value="Computer/Network Security">Computer/Network Security</option>
          <option value="Construction">Construction</option>
          <option value="Consumer Electronics">Consumer Electronics</option>
          <option value="Consumer Goods">Consumer Goods</option>
          <option value="Consumer Services">Consumer Services</option>
          <option value="Cosmetics">Cosmetics</option>
          <option value="Dairy">Dairy</option>
          <option value="Defense/Space">Defense/Space</option>
          <option value="Design">Design</option>
          <option value="E-Learning">E-Learning</option>
          <option value="Education Management">Education Management</option>
          <option value="Electrical/Electronic Manufacturing">Electrical/Electronic Manufacturing</option>
          <option value="Entertainment/Movie Production">Entertainment/Movie Production</option>
          <option value="Environmental Services">Environmental Services</option>
          <option value="Events Services">Events Services</option>
          <option value="Executive Office">Executive Office</option>
          <option value="Facilities Services">Facilities Services</option>
          <option value="Farming">Farming</option>
          <option value="Financial Services">Financial Services</option>
          <option value="Fine Art">Fine Art</option>
          <option value="Fishery">Fishery</option>
          <option value="Food Production">Food Production</option>
          <option value="Food/Beverages">Food/Beverages</option>
          <option value="Fundraising">Fundraising</option>
          <option value="Furniture">Furniture</option>
          <option value="Gambling/Casinos">Gambling/Casinos</option>
          <option value="Glass/Ceramics/Concrete">Glass/Ceramics/Concrete</option>
          <option value="Government Administration">Government Administration</option>
          <option value="Government Relations">Government Relations</option>
          <option value="Graphic Design/Web Design">Graphic Design/Web Design</option>
          <option value="Health/Fitness">Health/Fitness</option>
          <option value="Higher Education/Acadamia">Higher Education/Acadamia</option>
          <option value="Hospital/Health Care">Hospital/Health Care</option>
          <option value="Hospitality">Hospitality</option>
          <option value="Human Resources/HR">Human Resources/HR</option>
          <option value="Import/Export">Import/Export</option>
          <option value="Individual/Family Services">Individual/Family Services</option>
          <option value="Industrial Automation">Industrial Automation</option>
          <option value="Information Services">Information Services</option>
          <option value="Information Technology/IT">Information Technology/IT</option>
          <option value="Insurance">Insurance</option>
          <option value="International Affairs">International Affairs</option>
          <option value="International Trade/Development">International Trade/Development</option>
          <option value="Internet">Internet</option>
          <option value="Investment Banking/Venture">Investment Banking/Venture</option>
          <option value="Investment Management/Hedge Fund/Private Equity">Investment Management/Hedge Fund/Private
            Equity
          </option>
          <option value="Judiciary">Judiciary</option>
          <option value="Law Enforcement">Law Enforcement</option>
          <option value="Law Practice/Law Firms">Law Practice/Law Firms</option>
          <option value="Legal Services">Legal Services</option>
          <option value="Legislative Office">Legislative Office</option>
          <option value="Leisure/Travel">Leisure/Travel</option>
          <option value="Library">Library</option>
          <option value="Logistics/Procurement">Logistics/Procurement</option>
          <option value="Luxury Goods/Jewelry">Luxury Goods/Jewelry</option>
          <option value="Machinery">Machinery</option>
          <option value="Management Consulting">Management Consulting</option>
          <option value="Maritime">Maritime</option>
          <option value="Market Research">Market Research</option>
          <option value="Marketing/Advertising/Sales">Marketing/Advertising/Sales</option>
          <option value="Mechanical or Industrial Engineering">Mechanical or Industrial Engineering</option>
          <option value="Media Production">Media Production</option>
          <option value="Medical Equipment">Medical Equipment</option>
          <option value="Medical Practice">Medical Practice</option>
          <option value="Mental Health Care">Mental Health Care</option>
          <option value="Military Industry">Military Industry</option>
          <option value="Mining/Metals">Mining/Metals</option>
          <option value="Motion Pictures/Film">Motion Pictures/Film</option>
          <option value="Museums/Institutions">Museums/Institutions</option>
          <option value="Music">Music</option>
          <option value="Nanotechnology">Nanotechnology</option>
          <option value="Newspapers/Journalism">Newspapers/Journalism</option>
          <option value="Non-Profit/Volunteering">Non-Profit/Volunteering</option>
          <option value="Oil/Energy/Solar/Greentech">Oil/Energy/Solar/Greentech</option>
          <option value="Online Publishing">Online Publishing</option>
          <option value="Other Industry">Other Industry</option>
          <option value="Outsourcing/Offshoring">Outsourcing/Offshoring</option>
          <option value="Package/Freight Delivery">Package/Freight Delivery</option>
          <option value="Packaging/Containers">Packaging/Containers</option>
          <option value="Paper/Forest Products">Paper/Forest Products</option>
          <option value="Performing Arts">Performing Arts</option>
          <option value="Pharmaceuticals">Pharmaceuticals</option>
          <option value="Philanthropy">Philanthropy</option>
          <option value="Photography">Photography</option>
          <option value="Plastics">Plastics</option>
          <option value="Political Organization">Political Organization</option>
          <option value="Primary/Secondary Education">Primary/Secondary Education</option>
          <option value="Printing">Printing</option>
          <option value="Professional Training">Professional Training</option>
          <option value="Program Development">Program Development</option>
          <option value="Public Relations/PR">Public Relations/PR</option>
          <option value="Public Safety">Public Safety</option>
          <option value="Publishing Industry">Publishing Industry</option>
          <option value="Railroad Manufacture">Railroad Manufacture</option>
          <option value="Ranching">Ranching</option>
          <option value="Real Estate/Mortgage">Real Estate/Mortgage</option>
          <option value="Recreational Facilities/Services">Recreational Facilities/Services</option>
          <option value="Religious Institutions">Religious Institutions</option>
          <option value="Renewables/Environment">Renewables/Environment</option>
          <option value="Research Industry">Research Industry</option>
          <option value="Restaurants">Restaurants</option>
          <option value="Retail Industry">Retail Industry</option>
          <option value="Sales">Sales</option>
          <option value="Security/Investigations">Security/Investigations</option>
          <option value="Semiconductors">Semiconductors</option>
          <option value="Shipbuilding">Shipbuilding</option>
          <option value="Sporting Goods">Sporting Goods</option>
          <option value="Sports">Sports</option>
          <option value="Staffing/Recruiting">Staffing/Recruiting</option>
          <option value="Supermarkets">Supermarkets</option>
          <option value="Telecommunications">Telecommunications</option>
          <option value="Textiles">Textiles</option>
          <option value="Think Tanks">Think Tanks</option>
          <option value="Tobacco">Tobacco</option>
          <option value="Translation/Localization">Translation/Localization</option>
          <option value="Transportation">Transportation</option>
          <option value="Utilities">Utilities</option>
          <option value="Venture Capital/VC">Venture Capital/VC</option>
          <option value="Veterinary">Veterinary</option>
          <option value="Warehousing">Warehousing</option>
          <option value="Wholesale">Wholesale</option>
          <option value="Wine/Spirits">Wine/Spirits</option>
          <option value="Wireless">Wireless</option>
          <option value="Woodworking">Woodworking</option>
          <option value="Writing/Editing">Writing/Editing</option>
        </select>
      </div>
    )
  }

  renderExperiences() {
    return this.props.experiences.map(exp => {
      return (
        <div className="col mb-4" key={exp.company + exp.jobTitle} onClick={() => {
          this.props.deleteExperienceAction(exp.uuid)
        }}>
          <div className="card">
            <div className="card-body">
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h6 className="card-title">
                <span className="badge badge-info">{exp.industry ? exp.industry.name : null}</span>
              </h6>
              <h6 className="card-text">
                {exp.company}
              </h6>
              <h5 className="card-title"><strong>{exp.jobTitle}</strong></h5>
              <p
                className="card-text">{moment(exp.startDate).format('MMMM YYYY')} to {exp.endDate ? moment(exp.endDate).format('MMMM YYYY') : "Present"}</p>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">Work Experience</div>
        <div className="card-body">
          {this.props.loading ? <Loader/> : null}
          <div className="row row-cols-1 row-cols-md-2">
            {this.renderExperiences()}
          </div>
          <div className="card mb-3">
            <div className="card-header">Add work experience</div>
            <form onSubmit={this.handleSubmit.bind(this)} className="card-body" ref={this.formRef}>
              {this.renderField("Company", "company", "text", {required: true})}
              {this.renderField("Job Title", "jobTitle", "text", {required: true})}
              {this.renderField("Start Date", "startDate", "date", {required: true})}
              {this.renderField("End Date", "endDate", "date")}
              <div className="alert alert-info" role="alert">
                <small>You may leave "end date" empty if you are still in this role</small>
              </div>
              {this.renderIndustrySelect()}
              <button type="submit" className="btn btn-primary">Save</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const experiences = state.experience.experiences
  const loading = state.experience.loading
  return {experiences, loading}
}
export default connect(mapStateToProps, {
  fetchExperiencesAction,
  submitExperienceAction,
  deleteExperienceAction
})(Experience)