import React, {Component} from 'react'
import '../App.css'
import {
  Link,
  withRouter
} from "react-router-dom"
import TermsText from "../components/TermsText"

class TermsPage extends Component{
  render(){
    return (
      <div>
        <nav className="navbar navbar-light bg-light">
          <a className="navbar-brand" href="/#">
            JobConnect
          </a>
          <div className="form-inline my-2 my-lg-0">
            <Link to="/login">
              <button type="button" className="btn btn-info btn-sm">Log in</button>
            </Link>
          </div>
        </nav>
        <TermsText/>
      </div>
    )
  }
}

export default withRouter(TermsPage)