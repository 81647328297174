import React, {Component} from 'react'
import {getUserUUID} from "../redux/selectors"
import connect from "react-redux/es/connect/connect"
import {fetchProfileAction, updateProfileAction} from "../redux/actions"
import Loader from "./Loader"
import googleLogo from "../assets/images/powered_by_google_on_white_hdpi.png"

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      languages: []
    }
    this.formRef = React.createRef()
    this.autoCompleteRef = React.createRef()
  }

  componentDidMount() {
    this.props.fetchProfileAction()
    this.autoComplete = new window.google.maps.places.Autocomplete(
      this.autoCompleteRef.current,
      {types: ["(cities)"], componentRestrictions: {country: "za"}}
    )
    this.autoComplete.setFields(["address_components", "formatted_address"])
    this.autoComplete.addListener("place_changed", () => {
        const place = this.autoComplete.getPlace()
        const city = place.formatted_address
        this.setState({city: city})
        this.autoCompleteRef.current.value = city
      }
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.languages !== prevProps.languages) {
      this.setState({languages: this.props.languages})
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.updateProfileAction(this.state)
    this.setState({})
    this.formRef.current.reset()
  }

  renderField(label, name, type) {
    return (
      <div className="form-group">
        <label>{label}</label>
        <input type={type} className="form-control" defaultValue={this.props[name]}
               onChange={(e) => this.setState({[name]: e.target.value})}/>
      </div>
    )
  }

  handleLanguageChange(language, enable) {
    this.setState(prevState => {
      let langs = prevState.languages

      if (enable) {
        if (!langs.includes(language)) langs.push(language)
      } else {
        langs = langs.filter(existingLang => {
          return existingLang !== language
        })
      }

      return {languages: langs}
    })
  }


  renderLanguages() {
    const createCheckBoxFor = (language) => {
      return (
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="checkbox" id={language}
                 checked={this.state.languages.includes(language)}
                 onChange={e => this.handleLanguageChange(e.target.id, e.target.checked)}/>
          <label className="form-check-label" htmlFor={language}>{language}</label>
        </div>
      )
    }

    return (
      <div>
        <label>Languages</label>
        <div className="form-group">
          {createCheckBoxFor("Afrikaans")}
          {createCheckBoxFor("English")}
          {createCheckBoxFor("French")}
          {createCheckBoxFor("Sepedi")}
          {createCheckBoxFor("Sesotho")}
          {createCheckBoxFor("Southern Ndebele")}
          {createCheckBoxFor("Swazi")}
          {createCheckBoxFor("Tsonga")}
          {createCheckBoxFor("Tswana")}
          {createCheckBoxFor("Venda")}
          {createCheckBoxFor("Xhosa")}
          {createCheckBoxFor("Zulu")}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="card">
        {this.props.profileLoading || this.props.profileUpdating ? <Loader/> : null}
        <div className="card-header">Profile</div>
        <form className="card-body" onSubmit={this.handleSubmit.bind(this)} ref={this.formRef}>
          <div className="alert alert-info" role="alert">
            <small>Be sure to press the <strong>save</strong> button if any edits are made</small>
          </div>
          {this.renderField("First names", "firstNames", "text")}
          {this.renderField("Last names", "lastNames", "text")}
          {this.renderField("Email address", "email", "email")}
          {
            this.props.emailVerified ? null :
              <div className="alert alert-warning" role="alert">
                <small>This email has not been verified. You won't be able to download your CV until you verify it.
                  Please check your email inbox.
                </small>
              </div>
          }
          {this.renderField("Mobile Number", "mobile", "phone")}
          {this.renderLanguages()}
          {this.renderField("Birth date", "birthdate", "date")}

          <div className="search-location-input form-group">
            <label>City</label>
            <input
              ref={this.autoCompleteRef}
              placeholder={"Enter a City"}
              defaultValue={this.props.city}
              autoComplete="none"
              className="form-control"
              onChange={e=> this.setState({city: e.target.value})}
            />
            <img src={googleLogo} alt="powered-by-google" className="float-right py-2" style={{maxWidth: '110px'}}/>
          </div>

          <button type="submit" className="btn btn-primary">Save</button>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const userUUID = getUserUUID(state)
  const email = state.user.email
  const languages = state.user.languages
  const firstNames = state.user.firstNames
  const lastNames = state.user.lastNames
  const birthdate = state.user.birthdate
  const mobile = state.user.mobile
  const city = state.user.city
  const emailVerified = state.user.emailVerified
  const profileLoading = state.user.profileLoading
  const profileUpdating = state.user.profileUpdating
  return {
    userUUID,
    email,
    languages,
    firstNames,
    lastNames,
    birthdate,
    mobile,
    city,
    profileUpdating,
    profileLoading,
    emailVerified
  }
}
export default connect(mapStateToProps, {fetchProfileAction, updateProfileAction})(Profile)