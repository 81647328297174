import React, {Component} from 'react'
import connect from "react-redux/es/connect/connect"
import {deleteReferenceAction, fetchReferencesAction, submitReferenceAction} from "../redux/actions"
import {Link} from "react-router-dom"
import Loader from "./Loader"

class References extends Component {
  constructor(props){
    super(props)
    this.formRef = React.createRef()
  }

  componentDidMount() {
    this.props.fetchReferencesAction()
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.submitReferenceAction(this.state)
    this.setState({})
    this.formRef.current.reset()
  }

  renderField(label, name, type, options) {
    return (
      <div className="form-group">
        <label>{label}</label>
        <input type={type} className="form-control" {...options} defaultValue={this.props[name]}
               onChange={(e) => this.setState({[name]: e.target.value})}/>
      </div>
    )
  }

  renderReferences() {
    return this.props.references.map(ref => {
      return (
        <div className="col mb-4" key={ref.mobile} onClick={() => {
          this.props.deleteReferenceAction(ref.uuid)
        }}>
          <div className="card">
            <div className="card-body">
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 className="card-title"><strong>{ref.name}</strong></h5>
              <h5 className="card-title text-muted">
                <small>{ref.relation}</small>
              </h5>
              <p className="card-text">Mobile: {ref.mobile}</p>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div className="card">
        {this.props.loading ? <Loader/> : null}
        <div className="card-header">References</div>
        <div className="card-body">
          <div className="row row-cols-1 row-cols-md-2">
            {this.renderReferences()}
          </div>
          <div className="card mb-3">
            <div className="card-header">Add reference</div>
            <form onSubmit={this.handleSubmit.bind(this)} className="card-body" ref={this.formRef}>
              {this.renderField("Name", "name", "text", {required: true})}
              {this.renderField("Relation", "relation", "text", {required: true})}
              <div className="alert alert-info" role="alert">
                <small>An example of relation could be previous employer, soccer team coach or university professor
                </small>
              </div>
              {this.renderField("Mobile", "mobile", "phone", {required: true})}
              <div className="col m-3 p-1">
                <input className="form-check-input" type="checkbox" id="consent" required/>
                <label className="form-check-label mr-2 text-muted" htmlFor="consent">
                  <small>
                    This person has agreed to be a reference, and for their details to be stored as per our <Link
                    to="/terms">terms and conditions</Link>
                  </small>
                </label>
              </div>
              <button type="submit" className="btn btn-primary">Save</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const references = state.references.references
  const loading = state.references.loading
  return {references, loading}
}
export default connect(mapStateToProps, {
  fetchReferencesAction,
  submitReferenceAction,
  deleteReferenceAction
})(References)