import React, {Component} from 'react'
import connect from "react-redux/es/connect/connect"
import {downloadCVAction, fetchExperiencesAction, fetchProfileAction} from "../redux/actions"
import CV from "../pages/CV"

class MyCV extends Component {
  constructor(props) {
    super(props)
    this.cvRef = React.createRef()
  }

  handleDownloadClick() {
    let html = this.cvRef.current.innerHTML;
    const bootstrapLink = `<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossorigin="anonymous">`
    html = `<html><head>${bootstrapLink}</head><body>${html}</body></html>`
    this.props.downloadCVAction(html)
  }

  render() {
    return (
      <div>
        { this.props.emailVerified ? null :
          <div className="alert alert-warning" role="alert">
            You will not be able to download your CV until you've verified your email. Please check your
            email inbox.
          </div>
        }
        <div className="alert alert-info" role="alert">
          For the best looking CV, make sure all your information has been filled out in the other tabs
        </div>
        <div className="card">
          <div className="card-header">
            <div className="row justify-content-between">
              <div className="pt-1 col-4">Your CV</div>
              <button className="mx-3 btn btn-success btn-sm col-4" disabled={!this.props.emailVerified}
                      onClick={this.handleDownloadClick.bind(this)}>Download
              </button>
            </div>
          </div>
          <div ref={this.cvRef}>
            <CV/>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const emailVerified = state.user.emailVerified
  return {emailVerified}
}

export default connect(mapStateToProps, {fetchExperiencesAction, fetchProfileAction, downloadCVAction})(MyCV)