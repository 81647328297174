import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from "./reducers";
import mySaga from './sagas'
import createSagaMiddleware from 'redux-saga'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(sagaMiddleware))
);

// then run the saga
sagaMiddleware.run(mySaga)

export default store