import {
  FETCH_EXPERIENCES,
  FETCH_EXPERIENCES_FAILED,
  FETCH_EXPERIENCES_SUCCESS,
  LOGOUT, SUBMIT_EXPERIENCE
} from "../actionTypes"

const initialState = {
  experiences: [],
  loading: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_EXPERIENCES: {
      return {
        ...state,
        loading: true
      };
    }
    case SUBMIT_EXPERIENCE: {
      return {
        ...state,
        loading: true
      };
    }
    case FETCH_EXPERIENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        experiences: action.payload
      };
    }
    case FETCH_EXPERIENCES_FAILED: {
      return {
        ...state,
        loading: false,
        experiences: action.payload
      };
    }
    case LOGOUT: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
