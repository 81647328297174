import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILED,
  LOGIN,
  UPDATE_PROFILE_FAILED,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE,
  SIGNUP_SUBMIT_SUCCESS,
  SIGNUP_SUBMIT_FAILED,
  SIGNUP_SUBMIT, LOAD_COOKIE_SUCCESS, FETCH_PROFILE
} from "../actionTypes"

const initialState = {
  email: null,
  languages: null,
  firstNames: null,
  lastNames: null,
  birthdate: null,
  uuid: null,
  loginLoading: false,
  loginFailureMessage: null,
  profileUpdating: false,
  profileLoading: false,
  signupLoading: false,
  signupFailureMessage: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        loginLoading: true,
        loginFailureMessage: null
      };
    }
    case LOGIN_SUCCESS: {
      const {userUUID} = action.payload
      return {
        ...state,
        uuid: userUUID,
        loginLoading: false,
        loginFailureMessage: null
      };
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        loginLoading: false,
        loginFailureMessage: action.payload
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        ...initialState
      };
    }
    case LOAD_COOKIE_SUCCESS: {
      const {userUUID} = action.payload
      return {
        ...state,
        uuid: userUUID
      };
    }
    case SIGNUP_SUBMIT: {
      return {
        ...state,
        signupLoading: true,
        signupFailureMessage: null
      };
    }
    case SIGNUP_SUBMIT_FAILED: {
      return {
        ...state,
        signupLoading: false,
        signupFailureMessage: action.payload
      };
    }
    case SIGNUP_SUBMIT_SUCCESS: {
      const {userUUID} = action.payload
      return {
        ...state,
        uuid: userUUID,
        signupLoading: false,
        signupFailureMessage: null
      };
    }
    case FETCH_PROFILE: {
      return {
        ...state,
        profileLoading: true
      };
    }
    case FETCH_PROFILE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        profileLoading: false
      };
    }
    case FETCH_PROFILE_FAILED: {
      return {
        ...state,
        profileLoading: false
      };
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        profileUpdating: true
      };
    }
    case UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        ...action.payload.user,
        profileUpdating: false
      };
    }
    case UPDATE_PROFILE_FAILED: {
      return {
        ...state,
        profileUpdating: false
      };
    }
    default:
      return state;
  }
}
